import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Select, message, Button, Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'i18n';
import Modal from 'components/Modal';
import { logAndShowGenericError } from 'utils/log';
import APPLICATION_ACTIVITIES_QUERY from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
import Link from 'components/Link';
import { useEmailPreferences } from 'providers/EmailPreferencesProvider';
import useCanSendEmail from '../EmailComposeButton/useCanSendEmail';
import EmailModal from '../EmailComposeButton/EmailModal';
import REJECT_APPLICATION_MUTATION from './rejectApplicationMutation.gql';
import REJECTION_REASONS_QUERY from './rejectionReasonsQuery.gql';
import styles from './index.less';

function RejectApplicationModal({
  visible,
  onCancel,
  applicationId,
  organizationPk,
  application,
  contactDetails,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedReasonPk, setSelectedReasonPk] = useState(null);

  const emailPreferences = useEmailPreferences();
  const { hasEmailingModule, canSendEmail } = useCanSendEmail({
    contactDetails,
  });

  // Initialize checkbox state from context if available and if email can be sent
  const [suggestEmails, setSuggestEmails] = useState(() =>
    hasEmailingModule && canSendEmail
      ? (emailPreferences?.emailEnabled ?? true)
      : false,
  );

  const tooltipMessage = useMemo(() => {
    if (!hasEmailingModule) {
      return t('RejectApplicationModal_tooltip-noEmailModule');
    }
    if (!canSendEmail) {
      return t('RejectApplicationModal_tooltip-noEmailContacts');
    }
    return null;
  }, [hasEmailingModule, canSendEmail, t]);

  const { data, loading: reasonsLoading } = useQuery(REJECTION_REASONS_QUERY, {
    variables: { organizationPk },
    skip: !visible,
  });

  const [rejectApplication, { loading: rejectLoading }] = useMutation(
    REJECT_APPLICATION_MUTATION,
    {
      refetchQueries: [
        {
          query: APPLICATION_ACTIVITIES_QUERY,
          variables: { applicationPk: applicationId, organizationPk },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success(
          t('RejectApplicationModal_message-applicationRejected'),
        );
        if (suggestEmails && hasEmailingModule && canSendEmail) {
          setShowEmailModal(true);
        } else {
          onCancel();
        }
      },
      onError: () => {
        message.error(
          t('RejectApplicationModal_message-applicationRejectError'),
        );
      },
    },
  );

  const handleReasonChange = useCallback((reasonPk) => {
    setSelectedReasonPk(reasonPk);
  }, []);

  const handleSuggestEmailsChange = useCallback(
    (e) => {
      // Only update if email can be sent
      if (hasEmailingModule && canSendEmail) {
        const newValue = e.target.checked;
        setSuggestEmails(newValue);
        emailPreferences?.setEmailEnabled?.(newValue);
      }
    },
    [emailPreferences, hasEmailingModule, canSendEmail],
  );

  const handleReject = async () => {
    try {
      const { rejectReason } = await form.validateFields();
      setSelectedReasonPk(rejectReason);
      await rejectApplication({
        variables: {
          applicationPk: applicationId,
          reasonPk: rejectReason,
          organizationPk,
        },
      });
    } catch (error) {
      if (error.name === 'ValidationError') {
        // Form validation errors are handled automatically
        return;
      }
      // Log any other errors that might occur during the process
      logAndShowGenericError('Error in handleReject:', error);
    }
  };

  const handleEmailModalClose = useCallback(() => {
    setShowEmailModal(false);
    onCancel();
  }, [onCancel]);

  const handleTemplateSelect = useCallback(
    (templatePk) => {
      // Store the template choice in preferences if:
      // - We have a valid rejection reason selected
      // - A template was chosen
      // - The EmailPreferences context is available and supports template storage
      if (
        selectedReasonPk &&
        templatePk &&
        emailPreferences?.setRejectionTemplate
      ) {
        emailPreferences.setRejectionTemplate(selectedReasonPk, templatePk);
      }
    },
    [selectedReasonPk, emailPreferences],
  );

  const renderDropdown = useCallback(
    (menu) => (
      <>
        {menu}
        <div className={styles.dropdownFooter}>
          <Link to="/settings/rejection-reasons" className={styles.manageLink}>
            {t('RejectionApplicationModal_link-manageRejectionReasons')}
          </Link>
        </div>
      </>
    ),
    [t],
  );

  return (
    <>
      <Modal
        open={visible && !showEmailModal}
        onCancel={onCancel}
        title={t('RejectApplicationModal_title-rejectApplication')}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            {t('RejectApplicationModal_button-cancel')}
          </Button>,
          <Button
            key="reject"
            type="primary"
            onClick={handleReject}
            loading={rejectLoading}
          >
            {t('RejectApplicationModal_button-reject')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="rejectReason"
            label={t('RejectApplicationModal_label-rejectionReason')}
            data-testid="rejection-reason-form-item"
            rules={[
              {
                required: true,
                message: t(
                  'RejectApplicationModal_error-pleaseSelectRejectionReason',
                ),
              },
            ]}
          >
            <Select
              placeholder={t(
                'RejectApplicationModal_placeholder-selectRejectionReason',
              )}
              loading={reasonsLoading}
              disabled={reasonsLoading}
              onChange={handleReasonChange}
              data-testid="rejection-reason-select"
              dropdownRender={renderDropdown}
            >
              {data?.viewer?.rejectionReasons?.map((reason) => (
                <Select.Option role="option" key={reason.pk} value={reason.pk}>
                  {reason.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {hasEmailingModule && (
            <Form.Item>
              <Tooltip title={tooltipMessage}>
                <Checkbox
                  checked={suggestEmails && canSendEmail}
                  onChange={handleSuggestEmailsChange}
                  disabled={!canSendEmail}
                >
                  {t('RejectApplicationModal_checkbox-suggestEmails')}
                </Checkbox>
              </Tooltip>
            </Form.Item>
          )}
        </Form>
      </Modal>

      {showEmailModal && (
        <EmailModal
          data-testid="email-modal"
          application={application}
          contactDetails={contactDetails}
          isOpen={showEmailModal}
          onClose={handleEmailModalClose}
          reasonPk={selectedReasonPk}
          onTemplateSelect={handleTemplateSelect}
          defaultTemplate={
            selectedReasonPk
              ? emailPreferences?.getRejectionTemplate(selectedReasonPk)
              : null
          }
        />
      )}
    </>
  );
}

RejectApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  organizationPk: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired,
  contactDetails: PropTypes.array,
};

export default RejectApplicationModal;
