import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  MailOutlined,
  UserOutlined,
  UserAddOutlined,
  FormOutlined,
  SendOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

import { getUsername } from 'utils/user';
import Avatar from 'components/Avatar';
import { TimelineItem } from 'components/Timeline';
import DateWithIcon from 'components/DateWithIcon';
import EditCommentModalButton from 'components/EditCommentModal/EditCommentModalButton';

import styles from './Activity.less';

const EXCERPT_MIN_HEIGHT = 46;

const MAP_ICON_TO_COMPONENT = {
  user: UserOutlined,
  form: FormOutlined,
  userAdd: UserAddOutlined,
  mail: MailOutlined,
  send: SendOutlined,
};

function Activity({
  icon,
  createdAt,
  createdBy,
  applicationPk,
  commentPk,
  action,
  details,
  detailsExcerpt,
  isHighlighted,
  isSeen,
  attachments,
  isEditable = false,
  detailsToolbar,
  toolbar,
  isVisible,
}) {
  const detailsRef = useRef(null);

  const [height, setHeight] = useState(EXCERPT_MIN_HEIGHT);
  const [isExpanded, setExpand] = useState(false);
  const [expandable, setExpandable] = useState(false);

  useEffect(() => {
    if (
      detailsRef.current &&
      detailsRef.current.scrollHeight > EXCERPT_MIN_HEIGHT
    ) {
      setExpandable(true);
    }
  }, []);

  const onExpandToggle = () => {
    setExpand(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded && detailsRef.current) {
      setHeight(detailsRef.current.scrollHeight);
    } else {
      setHeight(EXCERPT_MIN_HEIGHT);
    }
  }, [isExpanded]);

  const isExpandable = detailsExcerpt && expandable;

  const DotIcon = MAP_ICON_TO_COMPONENT[icon];

  return (
    <TimelineItem
      dot={icon && <DotIcon aria-hidden />}
      className={cx(
        styles.activity,
        icon && styles.withIcon,
        isHighlighted && styles.highlighted,
        // isSeen is deprecated. It will be removed after setting Notification page as Home page.
        isSeen === false && styles.unseenActivity,
        isExpanded && styles.expanded,
        isExpandable && styles.expandable,
        detailsExcerpt && styles.withExcerpt,
        toolbar && styles.withToolbar,
        // Add class with display: none to hide a activity. For example when a user clicks "delete the notification",
        // but before the deleting mutation is sent. When a user clicks "restore the notification",
        // isVisible is set to true.
        !isVisible && styles.hide,
      )}
    >
      <div className={styles.header}>
        {createdBy &&
          (typeof createdBy === 'object' ? (
            <>
              <Avatar
                initials={createdBy.initials}
                size="mini"
                email={createdBy.email}
              />
              <span className={styles.username}>
                {getUsername(createdBy)}
              </span>{' '}
            </>
          ) : (
            <>
              <span className={styles.username}>{createdBy}</span>{' '}
            </>
          ))}
        <span className={styles.action}>{action}</span>{' '}
        <DateWithIcon date={createdAt} className={styles.date} />
        {isEditable && applicationPk && commentPk && (
          <EditCommentModalButton
            applicationPk={applicationPk}
            commentPk={commentPk}
            initialContent={details}
            createdBy={createdBy}
            styles={styles}
          />
        )}
      </div>
      {toolbar && <div className={styles.toolbar}>{toolbar}</div>}
      {details && (
        <div className={styles.detailsWrapper}>
          <div className={styles.detailsHeading}>
            {detailsExcerpt && (
              <div className={styles.excerpt} data-testid="details-excerpt">
                <strong>{detailsExcerpt}</strong>
              </div>
            )}
            {detailsToolbar && (
              <div className={styles.detailsToolbar}>{detailsToolbar}</div>
            )}
          </div>
          <div
            ref={detailsRef}
            className={styles.details}
            style={{
              height: !isExpandable ? 'auto' : height,
              // Calculate duration based on content height,
              // but for long content don't transition longer than 500ms
              transitionDuration: `${Math.round(
                Math.min(height * 0.3 + 100, 400),
              )}ms`,
            }}
          >
            <button
              type="button"
              onClick={onExpandToggle}
              className={styles.detailsContent}
              data-testid="details-content"
              tabIndex={isExpandable ? 0 : -1}
              aria-hidden={!isExpandable}
            >
              {details}
            </button>
          </div>
          {attachments && (
            <div className={styles.attachments}>
              {attachments.map((file) => (
                <div key={file.pk}>
                  <PaperClipOutlined className={styles.fileIcon} />
                  <a href={file.url} target="_blank" className={styles.link}>
                    {file.title}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </TimelineItem>
  );
}

Activity.propTypes = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.node,
  action: PropTypes.node,
  applicationPk: PropTypes.string,
  commentPk: PropTypes.string,
  isSeen: PropTypes.bool,
  details: PropTypes.node,
  detailsExcerpt: PropTypes.node,
  attachments: PropTypes.array,
  isEditable: PropTypes.bool,
  detailsToolbar: PropTypes.node,
  toolbar: PropTypes.node,
  isVisible: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};

export default Activity;
