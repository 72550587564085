import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import { SUPPORT_EMAIL } from 'consts/contact';

import notFoundImage from './404image.svg';
import styles from './index.less';

function Page404({ t }) {
  return (
    <div className={styles.container}>
      <img
        src={notFoundImage}
        alt={t('pageNotFound')}
        className={styles.image}
      />
      <div className={styles.textContainer}>
        <div className={styles.number}>404</div>
        <div className={styles.text}>
          <Trans i18nKey="404text">
            <div>Sorry, we couldn't find that page.</div>
            <div>Make sure the URL is correct.</div>
            <div>
              Go to
              <Link to="/"> home page</Link> or contact
              <a href={`mailto:${SUPPORT_EMAIL}`} className={styles.link}>
                {' '}
                support
              </a>
            </div>
          </Trans>
        </div>
      </div>
    </div>
  );
}

Page404.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(Page404);
