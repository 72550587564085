import React from 'react';
import PropTypes from 'prop-types';

import { getTheme } from 'utils/theme';
import { THEME_DEFAULT } from 'consts/theme';

import logo from './logo.svg';
import logoWhite from './logowhite.svg';

type LogoProps = {
  inMenu: boolean;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'>;

const THEME_LOGO = {
  [THEME_DEFAULT]: {
    logo,
    logoWhite,
  },
};

function Logo({ inMenu, ...rest }: LogoProps) {
  const theme = getTheme();
  const logoUrl = inMenu ? THEME_LOGO[theme].logo : THEME_LOGO[theme].logoWhite;

  return <img src={logoUrl} alt="Workate" {...rest} />;
}

Logo.propTypes = {
  inMenu: PropTypes.bool,
};

export default Logo;
